import Message from '../UI/Message'
import PageContent from '../UI/PageWrapper'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { API_REFRESH, API_URL } from '../../config/main'
import { useBodyClass } from '../../helpers'

const WWPayment = () => {
  useBodyClass('page-ww-payment')

  const promocodeField = useRef()
  const user = useSelector((state) => state.auth.user)
  const token = useSelector((state) => state.auth.token)

  const [message, setMessage] = useState()
  const [discount, setDiscount] = useState(0)

  // Курс доллара
  const usdRate = 80

  const [prices, setPrices] = useState({
    month_3: 0,
    month_6: 0,
    month_12: 0,
    unlimited: 0,
  })

  // Fetch prices
  const fetchPrices = useCallback(async (payload) => {
    try {
      const response = await fetch(
        API_URL +
          `?act=getprices&user_id=${payload.user_id}&token=${payload.token}`
      )

      if (!response.ok) {
        throw new Error('Ошибка доступа к API.')
      }

      const data = await response.json()

      //setIsLoading(false)

      if (data.type === 'error') {
      }

      if (data.type === 'success') {
        setPrices(data.prices)
      }
    } catch (error) {
      //setIsLoading(false)
    }
  }, [])

  const checkPromocode = useCallback(async (payload) => {
    try {
      const response = await fetch(
        API_URL +
          `?act=checkpromocode&user_id=${payload.user_id}&token=${payload.token}&promocode=${payload.promocode}`
      )

      if (!response.ok) {
        throw new Error('Ошибка доступа к API.')
      }

      const data = await response.json()

      //setIsLoading(false)

      if (data.type === 'error') {
        setMessage({ type: 'error', text: data.message })
      }

      if (data.type === 'success') {
        setMessage({
          type: 'success',
          text: 'Дополнительная скидка ' + data.data + '% активирована.',
        })

        setDiscount(data.data)

        console.log('discount', data.data)
      }
    } catch (error) {
      //setIsLoading(false)
    }
  }, [])

  // Apply promocode
  const applyPromocodeHandler = (e) => {
    const promocode = promocodeField.current.value

    checkPromocode({
      user_id: user.id,
      token: token,
      promocode: promocode,
    })
  }

  // Use effects
  useEffect(() => {
    fetchPrices({
      user_id: user.id,
      token: token,
    })
  }, [fetchPrices, token, user.id])

  useEffect(() => {
    const interval = setInterval(() => {
      fetchPrices({ user: user, user_id: user.id, token: token })
    }, API_REFRESH)

    return () => clearInterval(interval)
  }, [fetchPrices, user, token])

  return (
    <PageContent>
      <div className="page-title-wrapper">
        <h1 className="page-title">Оплата из-за рубежа и стран СНГ</h1>
      </div>
      <div className="page-description">
        <h3>Как оплатить зарубежными картами или PayPal?</h3>
        <p>Для оплаты из-за рубежа и СНГ:</p>
        <ul>
          <li>
            Перейдите на{' '}
            <a
              href="https://boosty.to/miukagirl/single-payment/donation/send/author"
              target="_blank"
              rel="noreferrer"
            >
              наш профиль в Boosty
            </a>{' '}
            и войдите в ваш профиль используя социальные сети или email.
          </li>
          <li>
            Нажмите кнопку{' '}
            <a
              href="https://boosty.to/miukagirl/single-payment/donation/send/author"
              target="_blank"
              rel="noreferrer"
            >
              "Отправить донат"
            </a>{' '}
            на нашей странице и введите сумму в USD согласно цене для зарубежной
            оплаты (см. ниже).
          </li>
          <li>
            В сообщении к донату <strong>обязательно укажите ваш email</strong>{' '}
            в системе Mi-Recipes и какой тариф вы покупаете.
          </li>
          <li>
            Оплатите донат. В течении дня после проверки ваш аккаунт будет
            активирован на выбранный период.
          </li>
          <li>
            По окончанию оплаченного периода вам нужно будет снова продлить его
            следуя данной инструкции.
          </li>
        </ul>
        <p>
          <a
            className="button button-fixed"
            href="https://boosty.to/miukagirl/single-payment/donation/send/author"
            target="_blank"
            rel="noreferrer"
          >
            Оплатить через Boosty
          </a>
        </p>
        <h3>Стоимость подписки для зарубежной оплаты</h3>

        <p className="promocode-form-wrapper">
          <input
            type="text"
            placeholder="Ваш промокод"
            ref={promocodeField}
            className="promocode"
          />
          <button className="button-fixed" onClick={applyPromocodeHandler}>
            Применить
          </button>
        </p>
        {message && <Message content={message.text} type={message.type} />}
        <ul>
          <li>
            <strong>На 3 месяца:</strong>{' '}
            {(
              Number(
                prices.month_3 * 3 - prices.month_3 * 3 * (discount / 100)
              ) / usdRate
            ).toFixed(0)}
            $
          </li>
          <li>
            <strong>На полгода:</strong>{' '}
            {(
              Number(
                prices.month_6 * 6 - prices.month_6 * 6 * (discount / 100)
              ) / usdRate
            ).toFixed(0)}
            $
          </li>
          <li>
            <strong>На год:</strong>{' '}
            {(
              Number(
                prices.month_12 * 12 - prices.month_12 * 12 * (discount / 100)
              ) / usdRate
            ).toFixed(0)}
            $
          </li>
          <li>
            <strong>Вечный доступ:</strong>{' '}
            {(
              Number(prices.unlimited - prices.unlimited * (discount / 100)) /
              usdRate
            ).toFixed(0)}
            $
          </li>
        </ul>
        <p>
          Если вы приобретали{' '}
          <a
            href="https://book.miukagirl.ru/?utm_content=mr&utm_medium=mr&utm_source=mr&utm_campaign=mr"
            target="_blank"
          >
            <strong>Основную книгу рецептов</strong>
          </a>{' '}
          (отдельно или в бандле с другими книгами) у вас скидка 50% на оплату
          любой подписки (скидка уже автоматически применена к ценам выше).
          Email с которого покупалась книга должен совпадать с email вашего
          аккаунта в Mi-Recipes. Скидка от промокода суммируется со скидкой 50%
          на основную книгу.
        </p>
        <p>
          <a
            className="button button-fixed"
            href="https://boosty.to/miukagirl/single-payment/donation/send/author"
            target="_blank"
            rel="noreferrer"
          >
            Оплатить через Boosty
          </a>
        </p>
        <p>
          Пожалуйста убедитесь что вы оплачиваете верную сумму, платежи через
          систему Boosty невозможно отменить и вернуть.
        </p>
      </div>
    </PageContent>
  )
}

export default WWPayment
